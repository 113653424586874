export function Link({ href, children }) {
  if (!href) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <a href={href}>
      {children}
    </a>
  );
}
export default function CarouselItem({ item }) {
  const {
    galleryImg: image,
    imgLink: imageLink,
    legacyImageUrl: legacyImage,
    legacyLink,
  } = item;

  let link;
  if (imageLink) {
    link = imageLink;
  } else if (legacyLink) {
    link = legacyLink;
  }

  if (image && image.url) {
    return (
      <Link href={link}>
        <img src={`/site-files${image.url}`} />
      </Link>
    );
  }

  if (legacyImage) {
    return (
      <Link href={link}>
        <img src={`/legacy-files/${legacyImage}`} />
      </Link>
    );
  }

  return null;
}
